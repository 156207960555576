.settings-header-title {
  color: #262261;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .settings-header-title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 4px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .settings-header-title {
    font-size: 18px;
    margin-bottom: 8px;
  }
}
