.sidebar-tablist-container {
  height: 100vh;
  width: 90px;
}

.sidebar {
  box-shadow: 3px 0px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 20% 0%;
  text-align: center;
  width: 100%;
}

.no_report {
  color: #262261;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
}

.side-bar-image-wrapper {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.tasking {
  padding: 17px 30px;
}

.reports {
  width: 100%;
  height: calc(100vh - 61px);
}

.side-bar-image-wrapper img {
  width: 100%;
  height: 100%;
}

.sidebar-list-items {
  list-style: none;
}

.map-controls-container {
  position: absolute;
}

.sidebar-tabpanel-container {
  overflow: hidden;
  width: 100%;
}

.sidebar-tabs {
  display: flex;
}

.data-explorer-map {
  width: 100%;
}

.data-explorer-map.gtlist {
  height: calc(100vh - 121px);
}

.data-explorer-map.emptygtlist {
  height: calc(100vh - 70px);
}

.user-management-container {
  display: flex;
  flex-direction: column;
  padding: 2%;
}

.home-sections {
  padding: 30px;
}

.data-explorer-container {
  height: calc(100vh - 61px);
}

.side-menu {
  padding-top: 20px;
  display: inline-block;
}

.sidebar_icon_menu {
  width: 46px;
  height: 46px;
}

.sidebar_icon_menu img {
  width: 100%;
  height: 100%;
}
