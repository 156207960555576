.disabled-text-field {
  border: 1px solid rgba(38, 30, 128, 0.6);
}
.cluster-wise-edit-fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.edit-cluster-name {
  width: 95%;
  border: 1px solid rgba(38, 30, 128, 0.6);
}

.text-field {
  border: 1px solid #261e80;
}

.text-field:focus {
  border: 1px solid #261e80;
  outline: none;
}

.list-officers-container {
  height: 200px;
  border: 1px solid #261e80;
  width: 50%;
  margin-left: auto;
  margin-right: 0px;
  border-radius: 5px;
}

.list-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 5px 0px;
}

.list-officers-container ul {
  overflow-y: auto;
  height: calc(200px - 38px);
}

.list-name-wrapper span {
  margin-left: 10px;
}

.officers-lists {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.css-b62m3t-container {
  width: 100%;
}

.image-container {
  cursor: pointer;
}

.image-container img {
  width: 100%;
  height: 100%;
}
/* React select */
.react-select__control {
  width: 232px;
  border: 1px solid #261e80 !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__control--is-focused {
  border: 1px solid #261e80 !important;
  outline: none;
  box-shadow: none !important;
}
