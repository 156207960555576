.data-validation-container {
  height: calc(100vh - 117px);
}

.data-validation {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.data-validation-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.tasking-clear-btn-div {
  width: 10%;
  float: right;
}

.tasking-clear-btn {
  background: white;
  border: none;
  color: #261e80;
  margin-right: 16px;
  text-decoration: underline;
  font-weight: 500;
  padding-top: 12px;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
  height: 50px;
  background: #f1f1f1;
}

.form-selection-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-selecton-group {
  display: flex;
  width: 95%;
  justify-content: space-around;
  align-items: center;
}

.header-left {
  flex-basis: 30%;
  display: flex;
  justify-content: space-around;
}

.header-right {
  flex-basis: 70%;
  display: flex;
  justify-content: flex-end;
}

.header-text {
  font-weight: 500;
  color: #7c7c7c;
}

.header-text.active {
  font-weight: 600;
  color: #262261;
}

.create-btn {
  width: 119px;
  height: 50px;
  border: none;
  background-color: #262261;
  border-radius: 5px;
  color: #f1f1f1;
  font-weight: 600;
}

.create-btn-disabled {
  width: 119px;
  height: 50px;
  border: none;
  background-color: #7c7c7c;
  border-radius: 5px;
  color: #f1f1f1;
  font-weight: 600;
  cursor: not-allowed !important;
}

.select-group-input {
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.select-group label {
  color: #7c7c7c;
  font-weight: 600;
}

.option-image-wrapper {
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.option-image-wrapper img {
  width: 100%;
  height: 100%;
}

.option-label {
  display: flex;
  align-items: center;
}

.option-label label {
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.create-task.react-select__control {
  border: 1px solid #cccccc !important;
}
