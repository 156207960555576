.registration-form {
  font-size: 14px;
}

.common-register-container.register-container {
  justify-content: space-between;
}

.register-form-card,
.sparta-logo-section-register {
  flex-basis: 50%;
}

.register-form-card {
  display: flex;
  justify-content: center;
}

.register-input {
  font-size: 14px;
  margin-right: 1%;
  padding: 2% 6%;
}

.registraion-form-group {
  margin-top: 1%;
}

.register-header {
  color: #262261;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.register-form-label {
  color: #8f8f8f;
  margin: 3px 0px;
}

.register-form-container {
  width: 50%;
  box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 5%;
}

.username-container {
  display: flex;
  justify-content: space-between;
}

.register-submit-button {
  background-color: #262261;
  color: #ffffff;
  font-size: 16px;
  margin-top: 5%;
  width: 100%;
}

.register-submit-button:hover {
  background-color: #262261;
  color: #ffffff;
}

.register-submit-button:disabled {
  background-color: #e0e0e0;
  color: #747474;
  border: none;
}

.sign-in-register {
  color: #494949;
  font-size: 14px;
  margin: 3% 0px 0px 0px;
  text-align: center;
}

.sign-in-link {
  color: #262261;
  margin: 0px 5px;
}

.password-eye {
  padding: 0px 5px;
}

.password-input {
  border: none;
}

.register-password-div,
.register-confirm-password-div {
  display: flex;
  flex-direction: flex-row;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

@media (min-width: 1300px) {
  .register-header {
    font-size: 30px;
  }

  .register-form {
    font-size: 20px;
  }

  .registraion-form-group {
    margin: 5% 2%;
  }
}

@media (max-width: 767px) {
  .register-header {
    text-align: center;
  }

  .sparta-maps-logo-sm {
    display: block;
    height: 50%;
    width: 50%;
  }

  .sparta-maps-logo {
    display: none;
  }

  .register-form-container {
    margin: 5% 15%;
  }

  .register-form-card {
    width: 100%;
  }
}

.tooltip-text {
  display: flex;
  visibility: hidden;
  position: absolute;
  z-index: 2;
  width: 268px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.tooltip-text::before {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  background-color: #192733;
  padding: 5px;
  z-index: 1;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

#right {
  bottom: -56px;
  top: -31px;
  left: 152%;
}

#right::before {
  top: 35%;
  left: -2%;
}

.hover-text {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
