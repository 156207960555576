.profile-settings-container {
  display: flex;
  justify-content: space-between;
}

.profile-settings-sub-container {
  width: 46%;
  display: flex;
  flex-direction: column;
}

.profile-settings-section {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 4%;
  height: 100%;
  position: relative;
}

.profile-settings-section .custom-button-position {
  margin-top: 0;
  position: absolute;
  bottom: 0;
}

.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10% 0;
}

.profile-image {
  height: 25%;
  width: 25%;
}

.profile-name-text {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  color: #8f8f8f;
}

.profile-details-wrapper-item {
  padding: 2% 0;
}

.profile-details-wrapper-item:nth-of-type(1) {
  padding-top: 0;
}

.profile-details-wrapper-item:last-child {
  padding-bottom: 0;
}

.profile-details-wrapper-label {
  font-weight: 400;
  font-size: 18px;
  display: block;
  margin-bottom: 2%;
  color: #8f8f8f;
}

.profile-details-wrapper-input-container {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  background-color: #f2f2f2;
}

.profile-details-input {
  width: 100%;
  background-color: #f2f2f2;
  border: none;
  color: #262261;
  font-size: 18px;
}

.profile-details-input:focus {
  border: none;
}

.save-password-button-container {
  display: flex;
  justify-content: center;
  margin-top: 15%;
}

.save-password-button-container button {
  position: absolute;
  bottom: 20px;
}

.save-button.MuiButton-contained {
  background-color: #262261;
  text-transform: capitalize;
  width: 110px;
  height: 36px;
}

.save-button.MuiButton-contained:hover {
  background-color: #262261;
}

@media (max-width: 768px) {
  .profile-settings-container {
    display: block;
  }

  .profile-settings-sub-container {
    width: 100%;
    margin-top: 5%;
  }

  .profile-settings-section {
    padding: 2%;
  }

  .profile-details-wrapper-label {
    font-size: 14px;
    margin-bottom: 1%;
  }

  .profile-details-input {
    font-size: 14px;
  }

  .save-password-button-container {
    margin-top: 5%;
  }

  .profile-name-text {
    font-size: 18px;
  }

  .save-password-button-container button {
    position: static;
  }

  .profile-image-container {
    margin: 5% 0;
  }

  .profile-details-wrapper-item {
    padding: 1% 0;
  }

  .profile-details-wrapper-item:nth-of-type(1) {
    padding-top: 0;
  }

  .profile-details-wrapper-item:last-child {
    padding-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .profile-settings-sub-container {
    width: 48%;
  }

  .profile-details-wrapper-label,
  .profile-details-input {
    font-size: 16px;
  }

  .profile-name-text {
    font-size: 20px;
  }
}
