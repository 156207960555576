.table-container {
  height: 90vh;
  width: 100%;
}

.form-table {
  max-height: calc(100vh - 200px) !important;
  height: 80vh;
}

.form-table-header {
  font-size: 16px;
  border-radius: 0px;
  background-color: #f1f1f1;
  color: #262261;
  padding: 15px;
  font-weight: 600;
}

.user-cluster-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.user-cluster-names {
  font-size: 14px;
  color: #727272;
}

.form-view-link {
  color: #262261 !important;
  cursor: pointer;
}

.form-view-link img {
  height: 17px;
  width: 16px;
  margin-right: 15px;
}

@media (max-width: 1300px) {
  .user-table {
    max-height: calc(100vh - 170px) !important;
    height: 80vh;
  }
}

.table-row {
  padding-left: 50px !important;
}

.table-action {
  padding-left: 20px !important;
}
