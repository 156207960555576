.home-analytics-container {
  display: flex;
  justify-content: space-between;
}

.home-page-analytics {
  height: 90vh;
  max-width: 55%;
  padding-right: 30px;
}

.analytics-card {
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  margin: 5px;
  padding: 1% 3%;
  width: 100vw;
}

.analytics-card-items {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 75%;
}

.analytics-item-1 {
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 500;
  margin: 2%;
}

.analytics-item-2 {
  color: #116366;
  font-size: 12px;
  font-weight: 600;
  margin: 2%;
}

.analytics-item-3 {
  color: #262261;
  font-size: 28px;
  font-weight: bold;
  margin: 2%;
}

.analytics-item-2-name {
  background-color: #f4f8f9;
}

.analytics-card-image {
  align-items: center;
  display: flex;
  justify-content: center;
}

.leaderboard-title {
  border-bottom: 1px solid #bdbdbd;
  color: #828282;
  display: flex;
  flex-basis: auto;
  justify-content: space-between;
  padding-bottom: 1%;
}

.weekly-leaderboard,
.forms-leaderboard {
  flex-direction: column;
  width: 100vw;
}

.analytics-point-chart {
  margin-top: 30px;
  height: 75%;
}

.home-sections .map-container .ol-viewport {
  border-radius: 10px;
}

.analytics-points {
  height: calc(100vh - 525px);
  margin: 30px 0px;
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
}

.analytics-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.analytics-weekly-select {
  flex-basis: 20%;
}

.point-line-chart {
  width: 87% !important;
  height: 108% !important;
}

.analytics-title label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}

.view-users-link,
.view-forms-link,
.view-users-link:hover,
.view-forms-link:hover {
  color: #262261;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.user-leaderboard-table {
  height: 150px;
  border: none;
  box-shadow: none;
  overflow: auto;
}

.leader-board-table {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
