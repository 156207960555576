.form-template .modal-content {
  border-radius: 0;
}

.form-template .modal-dialog {
  max-width: 600px;
}

.form-template-header {
  padding: 8px 16px;
  display: block;
  background-color: #eeeeee;
  border-bottom: 0;
}

.form-template-header-container {
  display: flex;
  justify-content: space-between;
}

.form-template-name,
.form-template-source {
  font-weight: 600;
  line-height: 21px;
  display: flex;
  align-items: center;
}

.form-template-name {
  font-style: italic;
  font-size: 18px;
  text-transform: uppercase;
  color: #261e80;
}

.form-template-source {
  font-style: normal;
  font-size: 12px;
  color: #262261;
}

.form-template-body {
  max-height: 75vh;
  overflow-y: auto;
  padding: 12px 50px;
}

.form-template-body-container {
  padding: 8px 0;
}

.mandatory-indicator {
  color: red;
}

.form-template-field-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #444444;
}

.form-template-field-text-box {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 100%;
  margin-top: 4px;
  padding-left: 10px;
}

.form-popup-logo {
  width: 85px;
  height: 30px;
}

.form-template-field-dropdown {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form-template-field-dropdown span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #434343;
}

.form-template-field-dropdown-item {
  margin-right: 18px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.form-template-field-dropdown-item-circle {
  width: 12px;
  height: 12px;
  border: 1px solid #4b4b4b;
  border-radius: 6px;
  margin-right: 8px;
}

.form-template-field-date {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 100%;
  margin-top: 4px;
}

.form-template-field-date span {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #444444;
}

.form-template-footer {
  padding: 8px 16px;
  display: block;
}

.form-template-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.form-template-footer-container span {
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  display: contents;
  color: #444444;
}
