.settings-list-item-container {
  display: flex;
  justify-content: space-between;
  padding: 1% 0 1% 0;
  border-bottom: 1px solid #bdbdbd;
}

.settings-list-item-container:first-of-type {
  padding-top: 0;
}

.settings-list-item-container:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.settings-list-item-text-content {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.settings-list-item-title {
  font-weight: 500;
  font-size: 18px;
  color: #828282;
}

.settings-list-item-description {
  font-weight: 500;
  font-size: 14px;
  color: #bdbdbd;
  margin-top: 1%;
}

@media (max-width: 768px) {
  .settings-list-item-container {
    display: block;
  }

  .settings-list-item-text-content {
    max-width: 100%;
  }

  .settings-list-item-title {
    font-size: 14px;
  }

  .settings-list-item-description {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .settings-list-item-title {
    font-size: 16px;
  }

  .settings-list-item-description {
    font-size: 12px;
  }
}
