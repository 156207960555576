.view-edit-user-form-label {
  color: #a4a4a4;
  display: flex;
  font-size: 14px;
  width: 100%;
  margin-bottom: 0px;
  padding: 8px 0px;
}

.edit-user-role-form-select,
.edit-user-cluster-form-select,
.edit-user-field-officers-form-select {
  width: 100%;
}

.view-edit-user-modal-body {
  max-height: 50vh;
  overflow-y: auto;
}

.view-edit-user-cluster-container,
.field-officers-container {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-height: 10vh;
  overflow-y: auto;
  padding: 10px;
  width: 100%;
}

.edit-user-cancel,
.edit-user-cancel:hover {
  background-color: #e0e0e0;
  border: 1px solid #cccccc;
  color: #747474;
}

.edit-user-save,
.edit-user-save:hover {
  background-color: #262261;
  color: #ffffff;
}

.edit-pencil-icon {
  cursor: pointer;
}
