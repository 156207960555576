.data-explorer-status-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 59px;
}

.approve-status-list {
  flex-basis: 24%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 38px;
  border-right: 3px solid #ececec;
}

.marked-list-sections {
  flex-basis: 28%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 38px;
  border-right: 3px solid #ececec;
}

.open-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-list {
  display: none;
  background: #f5f5f5;
  position: absolute;
  bottom: -11px;
  right: -1px;
  width: 100%;
  height: calc(100vh - 61px);
  z-index: 99;
}

.close-list.open {
  display: block;
}

.marked-point {
  color: #42444a;
  font-size: 14px;
  font-weight: 600;
}

.marked-select {
  margin: 7px 7px 21px 16px;
}

.reject-approve-btn {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 14px 35px;
}

.list-btn {
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #262261;
  padding: 7px 22px;
}

.list-btn.reject:disabled {
  border: 1px solid #c7c7cc;
  color: #c7c7cc !important;
  background-color: #fff !important;
}

.list-btn.approve:disabled {
  border: 1px solid #c7c7cc !important;
  background: #c7c7cc !important;
  color: #fff !important;
}

.list-btn.reject {
  color: #262261;
}

.list-btn.approve {
  color: #ffffff;
  background: #262261;
}

.select-all {
  text-align: right;
  color: #2f80ed;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  cursor: pointer;
}

.marked-point-list {
  margin: 0px 16px 31px 16px;
  height: calc(100vh - 245px);
  overflow: auto;
}

.marked-point-list li {
  position: relative;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-evenly;
}

.marked-point-list li p {
  background-color: #000c;
  border-radius: 5px;
  color: #fff;
  display: none;
  font-size: 12px;
  padding: 6px;
  position: absolute;
  text-align: start;
  top: 20px;
  right: 0px;
  z-index: 9;
  max-width: 90%;
}

.marked-point-list li p:before {
  background: #000;
  top: -9px;
  -webkit-clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  content: '';
  height: 9px;
  position: absolute;
  width: 15px;
}

.marked-point-list li span {
  color: #42444a;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 7px;
  display: inline-block;
  width: 67%;
}

.marked-point-list li span:hover ~ p {
  display: block;
}

.marked-point-list li label {
  margin-right: 7px;
  cursor: pointer;
}

.close-txt {
  padding: 10px;
  border-bottom: 0.5px solid #95969d;
  justify-content: center;
  display: flex;
}

.data-explorer-status-list li h1 {
  color: #262261;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  cursor: pointer;
  margin-right: 16px;
}

.arrow {
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.up-arrow {
  transform: rotate(180deg);
}

.arrow img {
  width: 100%;
  height: 100%;
}

.approve-status-list label {
  font-size: 14px;
  font-weight: 700;
}

.approve-status-list span {
  font-size: 14px;
  font-weight: 300;
  color: #42444a;
}

.approve-status-list:nth-child(1) label {
  color: #27ae60;
}

.approve-status-list:nth-child(2) label {
  color: #f2994a;
}

.approve-status-list:nth-child(3) label {
  color: #eb5757;
}

/* Input CheckBox */
.custom-checkbox input[type='checkbox'] {
  position: relative;
  cursor: pointer;
}

.custom-checkbox input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #262261;
  border-radius: 3px;
  background-color: white;
}

.custom-checkbox input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid #262261;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
