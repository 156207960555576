.map-controls {
  margin: 5px;
}
.map-controls-section {
  cursor: pointer;
  position: absolute;
  top: 40%;
  left: 95%;
  z-index: 99;
}

.basemap-section {
  cursor: pointer;
  position: absolute;
  top: 10%;
  left: 95%;
  z-index: 99;
}
