.add-new-user-container {
  display: block;
  width: 100%;
}

.add-new-user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.bg-color-tb {
  background-color: #ffeeeb;
}

.text_warning td,
.bg-color-tb td {
  padding-bottom: 40px !important;
}

.row-warning {
  position: absolute;
  left: 40px;
  bottom: 10px;
  color: #ff2e00;
}

.table:not(.caption-container) td {
  padding: 0.7rem 0rem;
  border-bottom-width: 0px;
}

.left-content {
  display: flex;
  align-items: center;
}

.back-icon {
  cursor: pointer;
}

.header_head {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #262261;
  margin-left: 15px;
}

.upload-csv-button {
  background-color: #262261;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  padding: 5px 20px;
}

.upload-csv-button:hover {
  background-color: #262261;
  color: #ffffff;
}

.add-user-form-container {
  display: flex;
  justify-content: space-between;
  padding: 25px 0 15px 0;
}

.add-user-form-group {
  margin: 0 1%;
  width: 100%;
}

.add-user-form-group:first-child {
  margin: 0 1% 0 0;
  width: 100%;
}

.add-user-form-group:last-child {
  margin: 0 0 0 1%;
  width: 100%;
}

.add-user-form-label {
  color: #a4a4a4;
  font-size: 12px;
}

.add-user-form-input {
  font-weight: 400;
  font-size: 14px;
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  line-height: 17px;
  padding: 10px 15px;
}

.add-user-form-input.lowercase::placeholder {
  text-transform: initial;
}

.add-user-form-errors {
  color: #ff0000;
  font-size: 12px;
}

.add-user-form-button-container {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 25px;
}

.add-user-form-button {
  background: #ffffff;
  border: 1px solid #262261;
  border-radius: 2px;
  padding: 7px 40px;
  cursor: pointer;
}

.add-user-form-button span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #261e80;
}

@media (max-width: 768px) {
  .add-user-form-group {
    margin: 0% 1%;
  }

  .add-user-form-label {
    color: #a4a4a4;
    font-size: 10px;
  }
}

.table-content {
  width: 100%;
}

.add_user_table {
  border: 0.5px solid #4f4f4f;
  border-radius: 4px !important;
  width: 100%;
}

.table-head {
  height: 75px;
  background-color: #f3f3f3;
}

.fix_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 27px 0px 44px;
}

.add_user_tr {
  position: relative;
  border-bottom: 1px solid #bdbdbd;
}

.fix_header h2 {
  font-size: 16px;
  font-weight: 600;
  color: #262261;
}

.fix_header p {
  font-weight: 500;
  font-size: 14px;
  color: #ff2e00;
  cursor: pointer;
}

.cluster_container {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 10px;
  height: 35px;
  border: 0.5px solid #a7a6a6;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 17.5px;
}

.cluster_container label {
  margin-right: 5px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #474747;
}

.cluster_container .close_cluster {
  cursor: pointer;
  margin-left: 5px;
}

.cluster_container .close_cluster img {
  width: 12px;
  height: 12px;
}

.basic {
  font-weight: 500;
  font-size: 14px;
  color: #4f4f4f;
}

.basic.width-16 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.role {
  color: #0ba488;
}

.table-head th {
  padding: 10px 0px 10px 0;
  font-size: 12px;
  font-weight: 600;
  color: #a4a4a4;
}

thead,
tbody tr {
  width: 100%;
  table-layout: fixed;
  display: table;
  border-bottom: transparent;
}

tbody td {
  vertical-align: middle;
}

.add_user_table_body {
  position: relative;
  border-bottom: 0.5px solid #bdbdbd;
  border-top: none;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 496px);
  display: block;
}

.add_user_table_body tr td {
  position: relative;
  padding: 9px 0px;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

tbody {
  display: table;
  width: 100%;
}

tbody .expandable-row {
  height: 80px;
  vertical-align: middle;
}

.create-user-button {
  background-color: #262261;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  padding: 5px 20px;
}

.create-user-button.disabled {
  border: none;
  background-color: #cccccc;
  color: #666666;
  opacity: 0.5;
  cursor: not-allowed;
}

.table_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 12px;
}

.delete-icon {
  cursor: pointer;
}

.green_dropdown img {
  padding-left: 10px;
}

@media (max-width: 768px) {
  .table-head th {
    font-size: 12px;
    padding: 8px 16px;
  }

  .table-container {
    overflow-x: auto;
    width: 100%;
  }

  .add_user_table {
    font-size: 12px;
  }

  .table_footer {
    height: auto;
    padding: 12px;
  }
}

.modal_header {
  display: flex;
  justify-content: space-between;
  padding: 16px 21px 0px 20px;
}

.modal_header h2 {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}

.modal_header img {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal_body {
  display: flex;
  justify-content: space-between;
  padding: 10px 80px 30px 50px;
}

.width-5 {
  width: 5%;
  text-align: center;
}

.width-3 {
  width: 3%;
  text-align: center;
}

.width-10 {
  width: 10%;
  text-align: left;
}

.width-16 {
  width: 16%;
  text-align: left;
}

.width-20 {
  width: 20%;
  text-align: left;
}

.width-34 {
  width: 34%;
  text-align: left;
}

.width-40 {
  width: 40%;
  text-align: left;
}

.padding-left-right-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cluster-order {
  display: flex;
  flex-wrap: wrap;
  max-height: 100px;
  overflow-y: auto;
  width: 90%;
}

.modal_body p {
  font-weight: 600;
  font-size: 15px;
  color: #868686;
  padding-left: 20px;
  text-align: left;
}

.modal_delete {
  background-color: #262261;
  color: #ffffff;
  align-items: flex-end;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 5px;
  float: right;
}

.delete_container {
  padding-right: 30px;
}

.error_msg {
  color: #ff2e00;
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  position: absolute;
  bottom: 10px;
  left: 0;
}

.hold-error {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  width: 90%;
  height: 60px;
  margin-top: 10px;
  white-space: nowrap;
}

.control-hover {
  visibility: hidden;
  position: absolute;
  right: 38px;
  top: 2px;
  padding: 4px 16px;
  border-radius: 4px;
  background: rgba(3, 3, 3, 0.75);
  width: 116px;
  align-items: center;
  justify-content: center;
  height: 28px;
}

.clear-btn {
  background: white;
  border: none;
  color: #261e80;
  margin-right: 15px;
  text-decoration: underline;
  font-weight: 500;
}
