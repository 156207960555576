.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.sparta-maps-watermark {
  bottom: 3%;
  left: 2%;
  position: absolute;
  right: 90%;
  z-index: 99;
}

.sparta-watermark-logo {
  height: 150%;
  width: 150%;
}

/* Map Loader */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 5px solid rgb(252, 252, 252);
  border-top-color: rgba(0, 0, 0, 0.6);
  animation: spinner 0.6s linear infinite;
}
