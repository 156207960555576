.sparta-logo-section {
  text-align: center;
  width: 50%;
}

.login-header {
  color: #262261;
  font-size: 28px;
  font-weight: 600;
}

.login-form-label {
  color: #8f8f8f;
  margin: 15px 0px 15px 0px;
}

.login-form-container {
  border-left: 2px solid #bdbdbd;
  width: 50%;
  padding: 0% 0% 0% 5%;
}

.login-input {
  width: 60%;
}

.username-input,
.password-input-container {
  display: flex;
  flex-direction: column;
}

.login-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #262261;
  color: #ffffff;
  font-size: 16px;
  margin: 20px 0px;
  padding: 2%;
  width: 60%;
  height: 67px;
}

.circle-progress span {
  width: 25px !important;
  height: 25px !important;
  margin-left: 10px;
}

.login-submit-button:active,
.login-submit-button:hover {
  background-color: #262261;
  color: #ffffff;
}

.login-submit-button:disabled {
  border: none;
  background-color: #e0e0e0;
  color: #747474;
}

.forgot-password {
  color: #262261;
  font-size: 14px;
  margin-top: 10px;
}

.forgot-password-link {
  color: #262261;
  text-decoration: none;
}

.sign-up {
  color: #494949;
  font-size: 14px;
  margin: 30px 0px 0px 0px;
  text-align: center;
  width: 60%;
}

.sign-up-link {
  color: #262261;
  margin: 0px 5px;
}

.login-error-message {
  font-size: 12px;
  color: #ff0000;
  margin: 5px 0px;
}

.sparta-maps-logo-sm {
  display: none;
}

.login-password-container {
  width: 60%;
  display: flex;
  flex-direction: flex-row;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

.password-input {
  width: 100%;
  border: none;
}

.password-input:focus {
  border: none;
}

.password-eye {
  padding: 0px 5px;
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1300px) {
  .login-form-label,
  .sign-up {
    font-size: 20px;
    font-weight: 600;
  }
}

/* Medium devices (992px and below that) */
@media (max-width: 991px) {
  .login-submit-button,
  .login-input,
  .sign-up {
    width: 75%;
  }

  .login-password-container {
    width: 75%;
  }
}

/* Small devices (768px and down) */
@media (max-width: 767px) {
  .login-submit-button,
  .login-input,
  .sign-up {
    width: 100%;
  }

  .login-password-container {
    width: 100%;
  }

  .password-input {
    width: 100%;
  }

  .sparta-maps-logo {
    display: none;
    height: 400px;
    width: 400px;
  }

  .login-form-label {
    font-size: 18px;
    font-weight: 600;
  }

  .login-input {
    font-size: 14px;
  }

  .sign-up {
    font-size: 12px;
  }

  .login-header {
    font-size: 28px;
    font-weight: 600;
  }

  .login-form-container {
    margin: 10% 20%;
    padding: 0px;
    width: auto;
  }

  .username-input,
  .password-input-container {
    text-align: left;
  }

  .sparta-maps-logo-sm {
    display: block;
    height: 75%;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
}
