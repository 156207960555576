.leaderboard .modal-content {
  border: none;
  border-radius: 4px;
  height: 65vh;
}

.leaderboard .modal-dialog {
  max-width: calc(100vw - 140px);
}

.leaderboard-header {
  padding: 15px 20px;
  display: block;
  border-bottom: none;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.leaderboard-header-container {
  display: flex;
  justify-content: space-between;
}

.leaderboard-header-container-items {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leaderboard-header-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-right: 20px;
  color: #262261;
}

.leaderboard-close-icon {
  width: 20px;
  height: 20px;
}

.leaderboard-body {
  overflow-y: auto;
  padding: 0;
}

.leaderboard-star-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.leaderboard-user-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.leaderboard-envelope-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.leaderboard-table-container {
  max-height: calc(100vh - 200px) !important;
}

.leaderboard-table-header {
  background-color: #eeeeee;
  color: #262261;
  padding: 15px;
}

.leaderboard-footer {
  border-top: none;
  padding: 0;
  background: #f7f7f7;
}

@media (max-width: 1300px) {
  .leaderboard-table-container {
    max-height: calc(100vh - 170px) !important;
  }
}
