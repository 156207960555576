.profile-account-container {
  border-radius: 5px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.profile-popup-username {
  font-size: 14px;
  margin-right: 10px;
}

.profile-popup-email {
  font-size: 12px;
}

.profile-popup-usertype {
  background-color: #f3f3f3;
  color: #7c7c7c;
  font-size: 12px;
  padding: 0px 5px;
}

.profile-popover-settings,
.profile-popover-logout {
  color: #606060;
  font-size: 12px;
  margin: 0px 10px;
}

.profile-popup-username,
.profile-popup-email {
  color: #878787;
}

.profile-popup-email-container {
  border-bottom: 1px solid #bebebe;
  padding-bottom: 5px;
}

.profile-popover-menu {
  cursor: pointer;
  margin: 5px 0px;
}

#popover-basic .popover-arrow {
  display: none;
}

.profile-account-logo {
  cursor: pointer;
}
