.cluster-table {
  max-height: calc(100vh - 200px) !important;
  height: 80vh;
}

.cluster-table-header {
  background-color: #f1f1f1;
  color: #262261;
  padding: 15px;
}

.cluster-field-officers-list,
.cluster-managers-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.cluster-field-officers-names,
.cluster-managers-names {
  font-size: 14px;
  color: #727272;
}

@media (max-width: 1300px) {
  .cluster-table {
    max-height: calc(100vh - 170px) !important;
  }
}
