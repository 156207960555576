.settings-tab-list {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #bdbdbd;
  margin: 1% 2% 0 2%;
  padding-bottom: 1%;
}

.settings-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: #828282;
  margin-right: 2%;
  padding: 0.3% 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.settings-tab:hover {
  background-color: #f2f2f2;
}

.settings-tab.react-tabs__tab--selected {
  background-color: #f0eff6;
  color: #262261;
}

.settings-tab-panel-container {
  padding: 2% 6% 2% 6%;
  overflow-y: scroll;
  height: calc(100vh - 143px);
}

@media (max-width: 768px) {
  .settings-tab {
    width: 100%;
    margin-right: 0;
    font-size: 14px;
  }

  .settings-tab-panel-container {
    padding: 1% 3% 1% 3%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .settings-tab {
    margin-right: 1%;
    width: 110px;
    font-size: 16px;
  }

  .settings-tab-panel-container {
    padding: 2% 3% 2% 3%;
  }
}
