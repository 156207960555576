.landing-constainer {
  height: 100vh;
  width: 100%;
}

.common-register-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
}

.sparta-logo-wrapper {
  flex-basis: 50%;
  text-align: center;
}

.form-group.forgot-group {
  flex-direction: column;
}

.sparta-logo {
  width: 395px;
  height: 126px;
  margin: 0 auto;
}

.sparta-logo img {
  width: 100%;
  height: 100%;
}

.powered-by-sections {
  margin-top: 90px;
}

.powered-by-text {
  font-size: 12px;
  line-height: 14px;
  color: #283988;
  margin-bottom: 24px;
}

.sparta-content-wrapper {
  padding: 0px 80px 0px 80px;
  border-left: 1px solid #bdbdbd;
  flex-basis: 50%;
}

.forgot-group {
  margin-bottom: 40px;
}

.forgot-title {
  margin-bottom: 48px;
}

.forgot-title h1 {
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  color: #262261;
}

.forgot-title p {
  margin-top: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #8f8f8f;
}

.common-form-label {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #8f8f8f;
  margin-bottom: 15px;
}

.common-form-control {
  padding: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #262261;
  width: 100%;
  border: 1px solid #828282;
  border-radius: 5px;
}

input:focus-visible {
  outline: inherit;
}

.forgot-phone-number {
  display: block;
  width: 330px;
  margin-bottom: 15px;
}

.otp-field {
  margin: 118px 0px;
}

.new-password-btn,
.verifi-btn,
.forgot-btn {
  text-align: center;
  width: 330px;
}

.new-password-btn {
  margin-top: 70px;
}

.forgot-btn .common-btn {
  margin-bottom: 27px;
}

.forgot-btn label {
  cursor: pointer;
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #262261;
}

.success-logo {
  margin-bottom: 80px;
  margin-left: 100px;
}

.details-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  float: left;
  color: #828282;
}

.success-error p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.success {
  color: #219653;
}

.error {
  color: #eb5757;
}

.success-error {
  height: 51px;
}

.green-btn {
  background: #219653 !important;
}

.green-btn label {
  margin-right: 15px;
}

/* otp */
.otp {
  background: #ffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 20px;
  display: inline-block;
}

.otp input:not(:last-child) {
  margin-right: 10px;
}

.otp input {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #262261;
  border: none;
  border-bottom: 1.5px solid #262261;
}

.otp input::selection {
  background-color: transparent;
}

/* common-btn */
.common-btn {
  border: inherit;
  padding: 15px;
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  background: #262261;
  border-radius: 5px;
}

button:disabled {
  cursor: alias;
  pointer-events: auto !important;
  color: #828282 !important;
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}

button:focus-visible {
  outline: none;
}

button:active {
  border-style: inherit;
}

/* common-password */
.common-password {
  display: block;
  position: relative;
  margin-left: 50px;
}

.passwordIcon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 10px;
}

.forgot-group-password {
  display: flex;
  padding-bottom: 15px;
}

.forgot-group-password label {
  margin: 10px;
  color: #4f4f4f;
  font-weight: 500;
}

.tooltip-pass {
  display: flex;
  visibility: hidden;
  position: absolute;
  z-index: 2;
  width: 268px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.tooltip-pass::before {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  background-color: #192733;
  padding: 5px;
  z-index: 1;
}

.hover-password:hover .tooltip-pass {
  visibility: visible;
}

#right-direction {
  bottom: -12px;
  top: -21px;
  left: 95%;
}

#right-direction::before {
  top: 35%;
  left: -2%;
}

.hover-password {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 14px;
}
