.action_modal_header_section {
  padding: 10px 10px 10px 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.crop_common_heading {
  color: #747474;
  font-size: 16px;
  font-weight: 600;
}

.close_icon {
  display: block;
  cursor: pointer;
}

.close_icon img {
  width: 100%;
  height: 100%;
}

.crop_details_container {
  display: flex;
  justify-content: space-between;
  padding: 14px 42px 14px 27px;
}

.crop_list_section_left {
  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.15);
}

.empty_img {
  color: #747474;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.crop_list_section {
  flex-basis: 50%;
}

.common_crop_list li {
  margin-bottom: 16px;
}

.common_crop_list li :where(label, span) {
  color: #696b72;
  font-size: 14px;
  font-weight: 500;
}

.crop_images {
  height: calc(100vh - 60vh);
  overflow-y: scroll;
  padding: 0px 20px;
}

.crop_details {
  height: calc(100vh - 70vh);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 20px;
}

.crop_details li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crop_details li label {
  display: flex;
  flex-basis: 45%;
  justify-content: space-between;
  padding-right: 12px;
}

.crop_details li span {
  display: block;
  flex-basis: 55%;
  padding-right: 30px;
}

.crop_details li span input {
  border-radius: 4px;
  border: 1px solid #c7c7cc;
  color: #95969d;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 7px;
  width: 100%;
}

.crop_images li:not(:last-child) {
  margin-bottom: 20px;
}

.crop_images li {
  width: 95%;
  height: 100%;
  position: relative;
}

.crop_images li img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.crop_approve_section {
  display: flex;
  justify-content: flex-end;
  padding: 16px 25px;
  border-top: 1px solid #f2f2f2;
}

.crop_approve_section h1 {
  margin-left: 9px;
  font-size: 14px;
  font-weight: 500;
}

.crop_approve_section .approved {
  color: #3db267;
}

.crop_approve_section .rejected {
  color: #eb5757;
}

.crop_approve_section button {
  border-radius: 4px;
  border: 1px solid #262261;
  height: 32px;
  padding: 5px 19px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.reject_btn {
  background-color: white;
}

.approve_btn {
  background-color: #262261;
  color: white;
  margin-left: 24px;
}

.download_btn {
  position: absolute;
  bottom: 0;
  right: -40px;
  border: transparent;
  background-color: transparent;
}

.btn_update_form {
  float: right;
  margin-right: 3rem;
  display: flex;
  background: rgb(255, 255, 255);
  border: none;
  font-size: 14px;
  color: rgb(38, 34, 97);
}

.textarea_comments {
  border: 1px solid #c7c7cc;
  padding: 6px 7px;
  width: 100%;
  border-radius: 4px;
  resize: none;
}
