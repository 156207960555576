.user-table {
  max-height: calc(100vh - 200px) !important;
  height: 80vh;
}

.user-table-header {
  background-color: #eeeeee;
  color: #262261;
  padding: 15px;
}

.user-cluster-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.user-cluster-names {
  font-size: 14px;
  color: #727272;
}

.user-view-link {
  color: #262261;
  cursor: pointer;
  font-weight: 600;
  padding-right: 10px;
  text-decoration: underline;
}

@media (max-width: 1300px) {
  .user-table {
    max-height: calc(100vh - 170px) !important;
  }
}

.img-info {
  cursor: pointer;
}
