.landing-navbar-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 50px;
  height: 70px;
  align-items: center;
}

.image-wrapper {
  height: 44px;
  width: 138px;
  cursor: pointer;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
}

.navbar-tab {
  display: flex;
  list-style-type: none;
  margin: 5px;
}

.navbar-list {
  color: #262261;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 25px;
  cursor: pointer;
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 767px) {
  .navbar-list {
    font-size: 14px;
    padding: 10px;
  }
}
