@font-face {
  font-family: 'Manrope';
  src: url('../font/Manrope-Regular.ttf');
}

* {
  font-family: 'Manrope', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
ul,
p {
  padding: 0px;
  margin: 0px;
}

.App {
  overflow-y: hidden;
}

ul {
  padding: 0px;
  margin: 0px;
}

li {
  list-style-type: none;
}

Link,
a {
  text-decoration: none;
  color: inherit;
}

.input-mandatory {
  font-size: 16px;
  color: #df0000;
  padding: 3px;
}

.form-control:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}

.input-form-disabled {
  background-color: #f9f9f9 !important;
  color: #828282;
}

/* To remove the line seperator in react-select dropdown */
.select__indicator-separator {
  display: none;
}

/* Openlayers default class -- START */
.ol-rotate,
.ol-attribution {
  display: none;
}

.ol-zoom {
  display: none;
}

/* Openlayers default class -- END */

/* React-datepicker default class -- START*/
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  border: none;
}

.react-datepicker-ignore-onclickoutside:focus-visible {
  outline: none;
}

.react-datepicker-wrapper:focus-visible,
.react-datepicker__input-container:focus-visible {
  border: none;
}

.react-datepicker__day--selected {
  background-color: #262261 !important;
}

.react-datepicker__header {
  background-color: transparent !important;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.select__menu.css-1nmdiq5-menu {
  z-index: 9999 !important;
}

/* React-datepicker default class -- END*/

/* React-bootstrap Popover default class -- START*/
.popover-body {
  min-width: 200px;
  padding: 0px;
}

/* React-tabs default class*/
.react-tabs__tab--selected,
.react-tabs__tab-list,
.react-tabs__tab {
  border: none;
}

.react-tabs__tab:focus::after {
  display: none;
}

/* MUI Table default class */
.MuiTablePagination-displayedRows {
  text-align: right !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: 0px !important;
}

/* Input feild number arrow */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Material UI Select Picker*/
.css-1oz3td-MuiAutocomplete-root {
  width: 100%;
}

.MuiAutocomplete-input {
  color: #42444a !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

/* .Mui-focused, */
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #262261 !important;
  color: #262261 !important;
}

.MuiInputLabel-root.MuiInputLabel-formControl {
  color: #42444a !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 0.5px solid #c7c7cc;
}

.MuiAutocomplete-option {
  font-size: 14px;
  font-weight: 600;
  color: #606060;
  padding: 9px 20px !important;
}

.MuiAutocomplete-option.Mui-focused,
.MuiAutocomplete-option[aria-selected='true'] {
  color: #fff;
  background-color: #262261 !important;
}

.MuiAutocomplete-tag {
  border-radius: 4px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled {
  background-color: #e0e0e0 !important;
}

/* Date Picker */
.MuiPickersDay-today {
  border-radius: 8px !important;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:hover,
.MuiButtonBase-root.MuiPickersDay-root:hover {
  background-color: #262261 !important;
  color: #fff;
  border-radius: 8px;
}
