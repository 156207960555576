.actions-icon {
  cursor: pointer;
}

.create-task-table-container {
  height: 80vh;
}

.actions-icon.padding {
  margin-right: 20px;
}

.status-green {
  color: #219653 !important;
}

.file-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 87px);
}

.select-button {
  border: none;
  background-color: #262261;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  width: 150px;
  height: 44px;
  vertical-align: bottom;
}

.upload-file {
  height: 40px;
  background: #f2f2f2;
  display: none;
}

.upload-file-header {
  margin-bottom: 25px;
  color: #262261;
  font-weight: 600;
}

.file-input-wrapper {
  display: flex;
}

.note-text {
  margin-bottom: 0px;
  margin-top: 5px;
  color: #696b72;
  font-size: 14px;
  font-weight: 500;
}

.file-ref {
  width: 300px;
  height: 44px;
  background: #f2f2f2;
  vertical-align: bottom;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #c7c7cc;
  font-weight: 500;
  border-radius: 5px 0px 0px 5px;
  font-size: 16px;
}
