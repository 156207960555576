.data_filters_container {
  max-width: 25%;
  padding-bottom: 16px;
}

.heading_sections {
  padding: 16px;
  border-bottom: 1px solid #c7c7cc;
}

.heading_sections span {
  font-size: 14px;
  font-weight: 500;
  color: #42444a;
}

.heading_sections span:last-child {
  color: #95969d;
  cursor: pointer;
}

.data-explorer-filter {
  padding: 25px 0px;
  overflow-y: auto;
  height: calc(100vh - 255px);
}

.filter_hide {
  display: none;
}

.filter_list {
  padding: 0px 25px;
}

.filter_list li {
  margin-bottom: 16px;
}

.explorer-form-select,
.explorer-cluster-select {
  margin: 15px 0px;
}

.explorer-download-button,
.explorer-download-button:hover {
  align-items: center;
  background-color: #262261;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.explorer-search-button,
.explorer-search-button:hover {
  background-color: #ffffff;
  border: 1px solid #262261;
  color: #262261;
}

.explorer-search-button,
.explorer-download-button {
  font-size: 16px;
  margin-top: 5%;
  width: 100%;
}

.data-explorer-button-disabled {
  background-color: #f1f1f1 !important;
  border: 1px solid #cccccc !important;
  color: #bdbdbd !important;
}

.run-validity-check {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #262261;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 16px;
}

.popover {
  max-width: unset;
  --bs-popover-arrow-width: 0;
  --bs-popover-arrow-height: 0;
  --bs-popover-arrow-border: unset;
  --bs-popover-border-width: 0;
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
  border-style: none;
  border-width: 0;
}

.popover-body {
  min-width: unset;
}

.popover-content {
  background: #282828;
  border-radius: 5px;
  padding: 15px 20px;
}

.popover-content-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 310px;
}

.popover-content-header-text-container {
  flex-basis: 33%;
  margin-bottom: 20px;
}

.popover-content-title {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: #afafaf;
}

.popover-content-action {
  display: none;
  justify-content: flex-end;
}

.popover-content-action img {
  margin-right: 5px;
  cursor: pointer;
}

.popover-content-action.pop-up-gitlist {
  display: flex;
  margin-bottom: 10px;
}

.popover-content-detail {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: #ffffff;
}

.popover-line {
  border: 0.75px solid #afafaf;
  margin-bottom: 15px;
}

.popover-farm-image-style {
  width: 120px;
  height: 80px;
  margin: 0 6px;
  cursor: pointer;
}

.popover-farm-image-style:first-child {
  margin-left: 0;
}

.popover-farm-image-style:last-child {
  margin-right: 0;
}

.popover-images-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
}

.popover-images-container {
  display: flex;
  justify-content: center;
}

.popover-left-arrow-image {
  color: white;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  cursor: pointer;
}

.select_user,
.select_cluster {
  margin-bottom: 16px;
}

.additional_header {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #c7c7cc;
  border-bottom: 1px solid #c7c7cc;
  padding: 16px;
  margin-top: 8px;
}

.arrow_down {
  transition: all 0.75s 0.1s;
  transform: rotate(360deg);
}

.arrow_right {
  transform: rotate(270deg);
  transition: all 0.75s 0.1s;
}

.additional_header label {
  color: #42444a;
  font-size: 14px;
  font-weight: 500;
}

.additional_header span {
  cursor: pointer;
}

.mandatory_field {
  color: #262261;
  font-size: 10px;
  font-weight: 500;
}

.popover-right-arrow-image {
  transform: rotate(180deg);
  color: white;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  cursor: pointer;
}

/* Action approve/reject modal */

.confirm-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reason-title span {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.reason-title img {
  width: 100%;
  height: 100%;
}

.confirm-modal img {
  width: 80%;
}

.confirm-modal p {
  margin-bottom: 20px;
}

.confirm-modal p,
.confirm-reject-modal label {
  color: #747474;
  font-size: 16px;
  font-weight: 600;
}

.reason-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #f2f2f2;
}

.confirm-reject-modal {
  display: flex;
  flex-direction: column;
}

.reject-btn-div {
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 0 16px;
}

.confirm-reject-modal button {
  border-radius: 4px;
  border: 1px solid #262261;
  height: 32px;
  padding: 5px 19px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 16px 16px 0px;
}

.reason-rejection {
  margin: 20px;
  border-bottom: 1px solid #f2f2f2;
  height: 300px;
  overflow-y: scroll;
}

.reason-rejection li {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.reason-rejection li .reason-text {
  display: inline-block;
  color: #747474;
  font-size: 14px;
  margin-left: 15px;
}

/* Create a custom radio button */
.circle-radio {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #747474;
}

.circle-radio:hover .checkmark {
  background-color: #fff;
}

.circle-radio input:checked + .checkmark {
  background-color: #262261;
  border: transparent;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.circle-radio input:checked + .checkmark:after {
  display: block;
}

.circle-radio .checkmark:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
