.password-settings-section {
  display: flex;
  justify-content: space-between;
}

.password-form-wrapper {
  width: 50%;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 2%;
}

.password-form-wrapper-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding: 3% 0;
}

.password-form-wrapper-item:first-child {
  padding-top: 0;
  border-bottom: 0;
}

.password-form-wrapper-item:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.password-form-wrapper-label {
  width: 35%;
  font-weight: 500;
  font-size: 16px;
  color: #606060;
}

.password-form-wrapper-input-container {
  width: 60%;
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.forgot-password-text {
  color: #262261;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
}

.update-password-button-container {
  display: flex;
  justify-content: center;
  margin-top: 15%;
}

.mobile-settings-section .update-password-button-container {
  margin-top: 10px;
}

.update-button.MuiButton-contained {
  background-color: #262261;
  text-transform: capitalize;
  width: 120px;
  height: 36px;
}

.update-button.MuiButton-contained:hover {
  background-color: #262261;
}

.validate-pass-container {
  width: 50%;
  padding: 5%;
}

.validate-pass-container-title {
  font-weight: 500;
  font-size: 20px;
  color: #333333;
}

.validate-pass-container ul li {
  font-weight: 400;
  font-size: 18px;
  color: #606060;
  margin: 2% 0 2% 17px;
  list-style: disc;
}

@media (max-width: 768px) {
  .password-settings-section {
    display: block;
  }

  .password-form-wrapper {
    width: 100%;
  }

  .password-form-wrapper-item {
    display: block;
    padding: 2% 0 2% 0;
  }

  .password-form-wrapper-label {
    width: 100%;
    font-size: 14px;
  }

  .password-form-wrapper-input-container {
    width: 100%;
  }

  .forgot-password-text {
    font-size: 14px;
  }

  .update-password-button-container {
    margin-top: 5%;
  }

  .validate-pass-container {
    width: 100%;
    padding: 5% 0 5% 0;
  }

  .validate-pass-container-title {
    font-size: 16px;
  }

  .validate-pass-container ul li {
    font-size: 14px;
    margin: 1% 0 1% 17px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .password-form-wrapper-label {
    font-size: 14px;
  }

  .forgot-password-text {
    font-size: 14px;
  }

  .validate-pass-container-title {
    font-size: 18px;
  }

  .validate-pass-container ul li {
    font-size: 16px;
  }
}
