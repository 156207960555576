.mobile-settings-section {
  padding: 2%;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  overflow: auto;
}

.button-group button {
  width: 120px;
  border: 1px solid #bdbdbd;
  text-transform: capitalize;
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 400;
  padding: 0.5% 0;
}

.button-group button:hover {
  background-color: transparent;
  border: 1px solid #bdbdbd !important;
}

.button-group button:first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.button-group button:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.selected-button {
  background-color: #262261 !important;
  border: 1px solid #262261 !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.combo-box-wrapper {
  display: flex;
}

.combo-box-wrapper .unit-input {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  font-size: 18px;
  padding-left: 12px;
  padding-right: 24px;
  margin-right: -12px;
  color: #262261;
}

.combo-box-wrapper .unit-input:focus::placeholder {
  color: transparent;
}

.combo-box-wrapper .unit-input:focus {
  outline: none;
}

.combo-box-wrapper .unit-input::placeholder {
  color: #bdbdbd;
}

@media (max-width: 768px) {
  .button-group button {
    font-size: 14px;
    margin-top: 2%;
    width: 70px;
  }

  .combo-box-wrapper .unit-input {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .button-group button {
    font-size: 16px;
    width: 100px;
  }

  .combo-box-wrapper .unit-input {
    font-size: 16px;
    padding-left: 6px;
    padding-right: 12px;
    margin-right: -6px;
  }
}
