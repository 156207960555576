.Upload_container {
  padding: 20px 20px 0px 20px;
}

.Upload_header {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.Upload_header h2 {
  font-weight: 600;
  font-size: 20px;
  color: #333333;
}

.bottom-spacer {
  height: 110vh;
}

.header {
  display: flex;
  justify-content: center;
  background-color: #fff;
  font-size: 14px;
}

.header button:disabled {
  cursor: alias;
  pointer-events: auto !important;
  color: #828282 !important;
  background-color: white !important;
  border-color: white !important;
}

.header_link {
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
  border-bottom: 3px solid transparent;
  background: white !important;
  color: #828282;
}

.header_link.selected {
  border-bottom: 3px solid #261e80;
  color: #261e80;
}

.section {
  height: 40vh;
}

.close-icon {
  padding-left: 10px;
}

.close-icon img {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.sign_icon {
  margin-top: 3px;
}

.Upload_body {
  display: flex;
  padding-top: 20px;
}

.Upload_body h3 {
  font-weight: 600;
  font-size: 15px;
  color: #333333;
  padding-bottom: 30px;
}

.csv_head {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #a4a4a4;
  padding-bottom: 10px;
}

.csv_head sup {
  color: #df0000;
}

.react-select__control.react-select__control {
  background: #f9f9f9;
  border: 1px solid #e0e0e0 !important;
  border-radius: 2px;
}

.custom-file-upload {
  display: flex;
}

.custom-file-upload input[type='file']::file-selector-button {
  border: 1px solid #261e80;
  background: #ffffff;
  padding: 10px 20px;
  border-radius: 2px;
  color: #261e80;
  cursor: pointer;
  margin-right: 20px;
  text-align: left;
}

.fileupload {
  display: flex;
  padding: 10px 0px 20px 0px;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.delimiter {
  display: flex;
  width: 60%;
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
}

.Upload_footer {
  margin-top: 40px;
  padding: 9px 0px 9px 0px;
  background-color: #f7f7f7;
}

.btn-submit {
  display: flex;
  justify-content: flex-end;
}

.next-btn {
  width: 15%;
  margin-right: 20px;
  background: #262261;
  border-radius: 2px;
  border-color: #262261;
}

.import-btn {
  margin-right: 20px;
  background: #262261;
  border-radius: 2px;
  border-color: #262261;
}

.import-btn:hover {
  text-decoration: none;
  color: white;
  background: #262261;
  border-color: #262261;
}

.import-btn:active {
  background: #262261 !important;
}

.next-btn-button {
  width: 25%;
  margin-right: 20px;
  background: #262261;
  border-radius: 2px;
  border-color: #262261;
  text-decoration-color: #fff !important;
}

.next-btn-button:hover {
  text-decoration: none;
  color: white;
  background: #262261;
  border-color: #262261;
}

.next-btn-button:active {
  background: #262261 !important;
}

.next-btn:hover {
  text-decoration: none;
  color: white;
  background: #262261;
  border-color: #262261;
}

.next-btn:disabled {
  background-color: #262261;
  border-color: #262261;
}

.next-btn:active {
  background: #262261 !important;
  box-shadow: none;
}

.back-btn {
  width: 15%;
  background: #e0e0e0;
  border-color: #e0e0e0;
  color: #747474;
  border-radius: 2px;
}

.back-btn:hover {
  text-decoration: none;
  color: #747474;
  background: #e0e0e0;
  border-color: #e0e0e0;
}

.back-btn:active {
  background-color: #747474 !important;
}

.import-header {
  display: flex;
  justify-content: left;
}

.loader_import {
  justify-content: center;
}

.back-btn.clicked:active {
  background: #e0e0e0;
}

.mapfield_container {
  display: block;
  width: 100%;
}

.form-group {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.form-group label {
  margin: 10px;
  color: #4f4f4f;
  font-weight: 500;
}

.csv_field {
  position: absolute;
  right: 38%;
}

.front_arrow {
  margin: auto;
  padding-right: 30px;
}

.field_name {
  display: flex;
  justify-content: space-between;
}

.field_name p {
  font-size: 12px;
  color: #a4a4a4;
  padding-left: 10px;
}

.col-4 {
  width: 25%;
}

.margin_hr {
  margin: 0px 0px 30px 0px;
}

.back-btn-button {
  width: 15%;
  background: #fff;
  border-color: #262261;
  color: #262261;
  border-radius: 2px;
}

.back-btn-button:hover {
  text-decoration: none;
  color: #262261;
  background: #fff;
  border-color: #262261;
}

.back-btn-button:active {
  background: #262261 !important;
}

.progress {
  height: 20px;
}

.bg-progress_check {
  background-color: #262261 !important;
}

.import_body h3 {
  font-weight: 600;
  font-size: 15px;
  color: #333333;
  padding: 20px 0px 30px 0px;
}

.import_body h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding-bottom: 10px;
}
