.switch-with-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 370px;
  margin-bottom: 5%;
}

.switch-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #828282;
}

.switch-status {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #bdbdbd;
}

.switch-status-active {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #04ac0b;
}

@media (max-width: 768px) {
  .switch-with-title-container {
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .switch-title {
    font-size: 14px;
  }
  .switch-status {
    font-size: 12px;
  }
  .switch-status-active {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .switch-with-title-container {
    width: 270px;
  }
  .switch-title {
    font-size: 16px;
  }
  .switch-status {
    font-size: 12px;
  }
  .switch-status-active {
    font-size: 12px;
  }
}
