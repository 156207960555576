.main-content {
  background-image: url('../../assets/images/background.jpg');
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 105px);
  margin: 35px 0px 0px 0px;
  padding: 0px 50px;
}

.satsure-sparta-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo-wrapper {
  width: 396px;
  height: 126px;
}

.powered-by-texts {
  margin-top: 80px;
  margin-bottom: 20px;
}

.powered-by-texts label {
  color: #283988;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.landing-page-logo {
  height: 100%;
  width: 100%;
}

.landing-page-content {
  display: flex;
  flex-direction: column;
  max-width: 65%;
  text-align: left;
  height: 80vh;
  justify-content: space-around;
}

.landing-page-container {
  display: flex;
  flex-direction: row;
}

.landing-text-1 {
  color: #262261;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0px;
}

.landing-text-2 {
  color: #262261;
  font-size: 25px;
  font-weight: bolder;
  margin: 10px 0px;
}

.landing-text-3 {
  color: #4f4f4f;
  font-size: 15px;
  font-weight: normal;
  margin: 10px 0px;
  text-align: justify;
  width: 65%;
}

.login-register {
  text-align: left;
  margin: 25px 0px;
}

.login-button {
  background-color: #262261;
  color: #ffffff;
  font-size: 16px;
  padding: 5px 25px;
}

.register-button {
  background-color: #ffffff;
  border-color: #262261;
  color: #262261;
  font-size: 16px;
  margin: 0px 10px;
  padding: 5px 25px;
}

.login-button:hover {
  background-color: #262261;
  color: #ffffff;
}

.register-button:hover {
  background-color: #ffffff;
  border-color: #262261;
  color: #262261;
}

.google-play-lg {
  color: #262261;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin: 2% 0%;
}

.google-play-sm {
  display: none;
}

@media (min-width: 1300px) {
  .landing-text-1 {
    font-size: 20px;
  }

  .landing-text-2 {
    font-size: 30px;
  }

  .landing-text-3 {
    font-size: 20px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 767px) {
  .main-content {
    margin: 30px 0px;
  }

  .landing-page-logo {
    width: 75%;
  }

  .google-play-sm {
    color: #262261;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    text-align: center;
  }

  .google-play-lg {
    display: none;
  }

  .landing-page-container {
    display: flex;
    flex-direction: column;
  }

  .landing-page-content {
    max-width: 100%;
    text-align: center;
  }

  .landing-text-3 {
    width: 100%;
  }

  .login-button,
  .register-button {
    font-size: 14px;
  }

  .google-play-logo {
    height: 75%;
  }

  .satsure-sparta-logo {
    margin: 10px;
    text-align: center;
  }

  .login-register {
    text-align: center;
  }
}
