.custom-modal .modal-head {
  font-size: 10px;
  color: black !important;
}

.box-area {
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.15);
  padding: 15px 15px 15px 15px;
  border-radius: 4px;
}

.box-content {
  display: flex;
  justify-content: space-between;
}

.box-content h1 {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 6px;
  color: #606060;
}

.box-area p {
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 8px;
  color: #606060;
}

.text_area {
  display: flex;
}

.text_area .form-control {
  margin-right: 8px;
}

.text_area_distance {
  background: #ffffff;
  border: 0.5px solid #e0e0e6;
  border-radius: 4px;
  width: 150px;
  height: 36px;
}

.text_area_distance ::placeholder {
  color: #e0e0e0;
}

.text_area_distance:focus {
  border: 0.5px solid #262261;
}

.text_area_distance:focus,
input:focus {
  color: #262261;
}

.text_area_unit {
  width: 78px;
  height: 36px;
  background: url(../../assets/images/Down_grey.svg) no-repeat 55px 15px;
  border: 0.5px solid #e0e0e6;
  border-radius: 4px;
  color: #828282;
  cursor: pointer;
}

.text_area_unit:focus {
  border: 0.5px solid #262261;
}

.text_area_unit:focus,
input:focus {
  color: #262261;
}

.download-data {
  width: 150px !important;
}

.text_area_area-container {
  display: flex;
}

.text_area_area {
  flex: 1;
  height: 36px;
  background: #ffffff;
  border: 0.5px solid #e0e0e6;
  border-radius: 4px;
}

.poly-select-container {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
}

.poly-select {
  flex-basis: 27%;
}

.text_area_area:focus {
  border: 0.5px solid #262261;
}

.text_area_area:focus,
input:focus {
  color: #262261;
}

.check_area {
  display: flex;
}

.check_area .form-check {
  margin-right: 10px;
}

.modal-footer {
  background-color: #f7f7f7;
  border: none;
}

.download-button {
  width: 136px;
  height: 36px;
  background: #262261;
  border-radius: 2px;
  border: none;
}

.download-button:hover {
  background: #262261;
}

.close-button {
  width: 100px;
  height: 36px;
  background: #e0e0e0;
  border-radius: 2px;
  border: none;
  color: #747474;
}

.close-button:hover {
  background: #d6d6d6;
}

.switch_checkbox .form-check-input {
  border-color: #95969d !important;
  width: 25px;
  height: 16px;
}

.switch_checkbox .form-check-input:checked {
  background-color: #04ac0b !important;
  border-color: #04ac0b !important;
  box-shadow: none !important;
}

.switch_checkbox .form-check-input[type='checkbox']:focus {
  border-color: #95969d;
  box-shadow: none !important;
}

.check_area .form-check-input[type='checkbox'] {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #262261;
  outline: none;
  cursor: pointer;
}

.check_area .form-check-input[type='checkbox']:checked {
  background: #262261;
}

.check_area .form-check-input[type='checkbox']:focus {
  box-shadow: none;
}

.error-text {
  display: block;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #eb5757;
  margin-top: 5px;
  height: 12px;
}

.error-text.visible {
  visibility: visible;
}

.error-text.hidden {
  visibility: hidden;
  height: 0;
}
