.navbar_container {
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.navbar_container li {
  display: flex;
  flex-basis: 33.33%;
}

.chola_logo {
  height: 60px;
  margin: 0 auto;
}

.chola_logo img {
  width: 100%;
  height: 100%;
}

.admin-header {
  align-items: center;
  color: #262261;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  padding: 1% 2%;
}

.user-type {
  background-color: #e0e0e0;
  border-radius: 2px;
  color: #828282;
  font-size: 12px;
  font-weight: 500;
  margin: 0px 10px;
  padding: 2px;
}

.add-user-button {
  width: 115px;
  background-color: #262261;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  height: 34px;
}

.add-user-button:active,
.add-user-button:hover {
  background-color: #262261;
  color: #ffffff;
}

.comonon-nav-btn {
  background-color: #262261;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 25px;
}

.create-task-button {
  margin-left: auto;
  margin-right: 30px;
}

.comonon-nav-btn:active,
.comonon-nav-btn:hover {
  background-color: #262261;
  color: #ffffff;
}

.user-filter {
  border: 1px solid #828282;
  border-radius: 5px;
  display: flex;
  margin-left: 20px;
  height: 34px;
}

.user-filter-btn {
  background-color: #ffffff;
  color: #262261;
  font-size: 12px;
  border-radius: 5px;
}

.user-filter-btn-active {
  background-color: #262261;
  color: #ffffff;
}

.user-filter-btn-active:active,
.user-filter-btn-active:hover {
  background-color: #262261 !important;
  border-color: transparent !important;
  color: #ffffff !important;
}

.request-search {
  width: 146px;
  position: relative;
}

.request-search img {
  position: absolute;
  inset: 5px;
}

.request-search .search-input {
  border: 1px solid #262261;
  outline: none;
  height: 29px;
  padding-left: 30px;
}

.search-input::placeholder {
  font-size: 14px;
  line-height: 16px;
}

.user-filter-wrapper {
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
}

.search-input:focus {
  outline: none;
}
